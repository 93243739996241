.custom-modal-xl {
    max-width: 90% !important;
}
.custom-modal-lg {
    max-width: 90% !important;
}

.search-btn, .clear-btn, .export-btn {
    background-color: #585858;
    color: white;
    border: none;
    
    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    
    font-size: 0.85rem;
    height: 45px; 
    cursor: pointer;
    top: 30px;
}

.clear-btn {
    background-color: #d9534f;
}

.custom-table th, .custom-table td {
    padding: 1rem;
    text-align: center;
}

.custom-table tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.custom-table tr:nth-child(even) {
    background-color: #ececec;
}

.status-capsule {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 25px;
    color: white;
    font-weight: bold;
    text-align: center;
}

.search-form {
    width: 100%;
    justify-content: space-between;
}

.search-input {
    width: 18%;
    margin-bottom: 10px;
}

.no-document {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    color: #dc3545;
    font-size: 14px;
    font-weight: bold;
    background-color: transparent;
    border: 1px solid #dc3545;
}

.btn-editar {
    background-color: #585858;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
}

.btn-excluir {
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
}

.btn-criar-feriado {
    background-color: #585858;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
}
