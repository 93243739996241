/* Dashboard geral
.dashboard-container {
    padding: 2rem;
  }
  
  .task-card {
    background-color: #2f2d39;
    color: white;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .task-count {
    font-size: 2.5rem;
    margin: 0;
  }
  
  .card-title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .section-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .task-list {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 10px;
  }
  
  .overdue-table th, .overdue-table td {
    padding: 1rem;
    text-align: center;
  }
  
  .overdue-table tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  .overdue-table tr:nth-child(even) {
    background-color: #ececec;
  }
  
  .status-overdue {
    color: red;
    font-weight: bold;
  }
   */

   .dashboard-container {
    margin-top: 20px;
  }
  
  .task-card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .task-card .card-title {
    font-size: 1.2rem;
  }
  
  .task-card .task-count {
    font-size: 2rem;
  }
  
  .section-title {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #34495e;
  }
  
  .table {
    margin-top: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .table th, .table td {
    vertical-align: middle;
    text-align: center;
  }
  
  .table tbody tr:nth-child(odd) {
    background-color: #f8f9fa;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #ffffff;
  }
  
  .table-hover tbody tr:hover {
    background-color: #e8f6ff;
  }
  
  .chart-container {
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }  