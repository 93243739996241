/* Admin.css */

/* Estilos existentes */

.wrapper {
  display: flex;
  height: 100vh; /* Ocupa a altura total da viewport */
  overflow: hidden;
}

.sidebar {
  flex: 0 0 250px; /* Largura fixa para o sidebar */
  height: 100%;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  height: 100%;
}

/* Personalização da barra de rolagem para .main-content */

/* Track */
.main-content::-webkit-scrollbar {
  width: 8px;
  background-color: #fff;
}

/* Handle */
.main-content::-webkit-scrollbar-thumb {
  background-color: rgba(77, 183, 162, 0.51);
  border-radius: 20px;
}

/* Handle on hover */
.main-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(77, 183, 162, 0.68);
  border-radius: 20px;
}

/* Responsividade */
@media (max-width: 768px) {
  .sidebar {
    flex: 0 0 200px; /* Ajusta a largura do sidebar em telas menores */
  }
}
