.bg-translucent-pharmacy {
  background: linear-gradient(90deg, rgba(88,88,88,1) 10%, rgba(134,134,134,1) 54%, rgba(198,198,198,1) 88%, rgba(240,240,240,1) 100%, rgba(250,250,250,1) 100%) !important;
}

.bg-translucent-pharmacy-recruiter {
  background: linear-gradient(90deg, rgba(88,88,88,1) 10%, rgba(134,134,134,1) 54%, rgba(198,198,198,1) 88%, rgba(240,240,240,1) 100%, rgba(250,250,250,1) 100%) !important;
}

  @media (max-width: 575px) {
    .bg-translucent-pharmacy-recruiter {
      margin-bottom: 110px !important;
    }
  }

  .button-custom,
  .button-custom:hover,
  .button-custom:active,
  .button-custom:focus {
    border-color: #640764 !important;
    color: #FFF !important;
    background-color: #640764 !important;
  }

  .button-custom-recruiter,
  .button-custom-recruiter:hover,
  .button-custom-recruiter:active,
  .button-custom-recruiter:focus {
    border-color: #004C4E !important;
    color: #FFF !important;
    background-color: #004C4E !important;
  }