.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #25d366;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
}

.whatsapp-button svg {
    width: 50%;
    height: auto;
}

.help-box {
    position: absolute;
    bottom: 100%;
    right: 50%;
    transform: translateX(50%);
    background-color: rgba(255, 255, 255, 0.9); 
    border-radius: 15px; 
    padding: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 999;
    width: 250px; 
}

.help-box span {
    font-weight: bold;
}

.help-box button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    color: #888888;
}
