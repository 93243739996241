.bg-gradient-info-custom {
  background: #404040;
}

.bg-gradient-info-custom-bottom {
  background: #585858;
}

.text-login-1 {
  font-family: Noto Sans Display;
  font-size: 3em;
  color: white;
}

.text-student {
  font-size: 75px;
  color: white;
}

.description-login {
  font-size: 15px;
  width: 70%;
  color: white;
}

@media (max-width: 1020px) {
  .hide-on-small-screen {
    display: none;
  }
}

.position-card-login {
  margin-top: -14rem;
}

@media (max-width: 992px) {
  .position-card-login {
    margin-top: -11rem;
  }
}