/* Estilos para os campos de filtro */
.filter-section .search-form {
    width: 100%;
    display: flex;
    gap: 15px;
}

.search-input {
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.search-btn {
    background-color: #585858;
    color: white;
    border-radius: 8px;
    padding: 10px 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.search-btn:hover {
    background-color: #404040;
}

.custom-table th, .custom-table td {
    padding: 1rem;
    text-align: center;
}

.custom-table tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.custom-table tr:nth-child(even) {
    background-color: #ececec;
}

.customer-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.edit-btn, .delete-btn {
    margin: 0 5px;
    color: white;
    border: none;
}

.edit-btn {
    background-color: #585858;
}

.delete-btn {
    background-color: #d9534f;
}

.no-results {
    color: red;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}

/* Modal Custom Styles */
.modal-header-custom {
    background-color: #585858;
    color: white;
}

.modal-body-custom {
    padding: 2rem;
}

.image-dropzone {
    border: 2px dashed #585858;
    border-radius: 5px;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    color: #585858;
}

.image-dropzone p {
    margin: 0;
}

.image-preview {
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.create-btn {
    background-color: #585858;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.create-btn:hover {
    background-color: #404040;
}
