.search-btn, .clear-btn, .export-btn {
    background-color: #585858;
    color: white;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.search-btn:hover, .clear-btn:hover, .export-btn:hover {
    background-color: #404040;
}

.clear-btn {
    background-color: #d9534f;
}

.custom-table th, .custom-table td {
    padding: 1rem;
    text-align: center;
}

.custom-table tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.custom-table tr:nth-child(even) {
    background-color: #ececec;
}

.status-capsule {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 25px;
    color: white;
    font-weight: bold;
    text-align: center;
}

.search-form {
    width: 100%;
    justify-content: space-between;
}

.search-input {
    min-width: 150px;
    margin-bottom: 10px;
}

.tag {
    display: inline-block;
    margin: 0 4px;
    padding: 4px 8px;
    border: 1px solid currentColor;
    border-radius: 12px;
    color: #007BFF;
    font-size: 14px;
    background-color: transparent;
}

.no-document {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    color: #dc3545;
    font-size: 14px;
    font-weight: bold;
    /* background-color: rgba(220, 53, 69, 0.1); */
    background-color: transparent;
    border: 1px solid #dc3545;
}
