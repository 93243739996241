/* Estilos para os campos de filtro */
.filter-section .search-form {
    width: 100%;
}

.search-btn,
.clear-btn,
.export-btn {
    background-color: #585858;
    color: white;
    border: none;

    border-radius: 6px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    font-size: 0.85rem;
    height: 45px;
    cursor: pointer;
    top: 30px;
}

.custom-table th,
.custom-table td {
    padding: 1rem;
    text-align: center;
}

.custom-table tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.custom-table tr:nth-child(even) {
    background-color: #ececec;
}

.customer-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.edit-btn,
.delete-btn {
    margin: 0 5px;
    color: white;
    border: none;
}

.edit-btn {
    background-color: #585858;
}

.delete-btn {
    background-color: #d9534f;
}

.no-results {
    color: red;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}

/* Modal Custom Styles */
.modal-header-custom {
    background-color: #585858;
    color: white;
}

.modal-body-custom {
    padding: 2rem;
}

.image-dropzone {
    border: 2px dashed #585858;
    border-radius: 5px;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    color: #585858;
}

.image-dropzone p {
    margin: 0;
}

.image-preview {
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.create-btn {
    background-color: #585858;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.create-btn:hover {
    background-color: #404040;
}

.button-adjustment {
    margin-top: -12px;
}

.no-document {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    color: #dc3545;
    font-size: 14px;
    font-weight: bold;
    /* background-color: rgba(220, 53, 69, 0.1); */
    background-color: transparent;
    border: 1px solid #dc3545;
}