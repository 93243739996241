.butom-search-jobs,
.butom-search-jobs:hover,
.butom-search-jobs:active,
.butom-search-jobs:focus {
  color: #fff !important;
  background-color: #004C4E !important;
  border: none !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 16px !important;
  display: flex !important;
  height: 41px !important;
  border-radius: 20px !important;
}

.butom-search-candidate,
.butom-search-candidate:hover,
.butom-search-candidate:active,
.butom-search-candidate:focus {
  color: #fff !important;
  background-color: #004C4E !important;
  border: none !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 16px !important;
  display: flex !important;
  height: 41px !important;
  border-radius: 20px !important;
}

@media (max-width: 767px) {
  .butom-search-candidate {
    width: 45px !important;
    margin-left: 10px !important;
  }
}

.line-search-jobs-inputs {
  height: 34px;
  width: 2px;
  position: relative;
  border-radius: 20px;
  margin-left: 6px;
  z-index: 5;
}

.profile-photo-navbar {
  left: -19px !important;
  top: -5px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
  object-position: center center !important;
}

@media (max-width: 535px) {
  .nav-style-dev {
    padding: 0 !important;
  }
}


/* Estilo para o input de range */
.custom-range {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d8d8d8;
  outline: none;
}

/* Estilo para o círculo do input de range */
.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #8898aa; /* Cor do círculo */
  cursor: pointer;
  margin-top: -5px; 
}

.custom-range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: #4CAF50; /* Cor do círculo */
  cursor: pointer;
}
